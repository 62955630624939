import React from 'react';
import { Button } from '@material-ui/core';
import scrollTo from 'scroll-to-element'

const RegisterButton = () => {
    const handleClick = () => {
        scrollTo('#root', {
            duration: 1000,
        })
    }

    return (
        <Button className="green" fullWidth size="large" onClick={handleClick}>
            Cadastre-se agora para receber as melhores ofertas
        </Button>
    );
}

export default RegisterButton;