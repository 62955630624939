import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// CSS Imports
import './App.css';

// Components
import { Grid, Container, Typography } from "@material-ui/core"
import Register from './components/Register'
import Product from './components/Product';
import Details from './components/Details';
import RegisterButton from './components/RegisterButton';

// Files
import ImgDestaque from './images/destaque.png'
import DestaqueBg from './images/bg.jpg'
import DellLogo from './images/logo.png'
import WhiteDellLogo from './images/logo_branco.png'
import NotebookImage from './images/produto1.png'
import DesktopImage from './images/produto2.png'
import ServerImage from './images/produto3.png'
import BackpackImage from './images/produto4.png'
import RackImage from './images/produto5.png'
import SwitchImage from './images/produto6.png'
import AccessPointImage from './images/produto7.png'

// Styles
const useStyles = makeStyles(() => ({
  topBg: {
    backgroundImage: `url(${DestaqueBg})`,
    backgroundSize: 'cover'
  },
  topContainer: {
    paddingBottom: 32,
    paddingTop: 12,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  topTitle: {
    marginTop: 36,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media screen and (max-width: 960px)': {
      height: 'auto',
      padding: 12
    }
  },
  highLightImage: {
    maxWidth: '100%',
    '@media screen and (max-width: 960px)': {
      marginTop: 24,
      marginBottom: 24,
    }
  },
  footer: {
    width: '100vw !important',
    padding: 28,
    display: 'flex',
    alignItems: 'center',
  }
}))

// Constants
const products = [
  {
    name: 'Notebooks',
    image: NotebookImage
  },
  {
    name: 'Desktops',
    image: DesktopImage
  },
  {
    name: 'Servidores',
    image: ServerImage
  },
  {
    name: 'Acessórios',
    image: BackpackImage
  }
]

const productDetails = [
  {
    title: 'Gerenciamento simplificado',
    name: 'Servidores Torre PowerEdge',
    image: ServerImage,
    description: 'Oferecemos um servidor empresarial em formato torre, prático e básico que possibilita a organização de seus dados em um único repositório de dados central, assim, você pode se concentrar nos seus negócios. Ideal para iniciar sua infraestrutura.'
  },
  {
    title: 'Escalabilidade horizontal',
    name: 'Servidores Rack PowerEdge',
    image: RackImage,
    description: 'Centralize seus dados e otimize o espaço do seu escritório, chassis em formato rack que proporcionam escalabilidade horizontal. Ideal para pequenas empresas focadas em crescimento.'
  },
  {
    title: 'Soluções de rede',
    name: 'Switches',
    image: SwitchImage,
    description: 'Conheça a linha de Switches X-series e encontre o modelo ideal para construir uma infraestrutura de rede de pequeno porte.'
  },
  {
    title: 'Soluções de rede',
    name: 'Pontos de acesso Aerohive',
    image: AccessPointImage,
    description: 'Equipamentos projetados para modernizar a sua rede wireless. Ponto de acesso de última geração com performance e resiliência de nível empresarial. Ideal para ambientes internos.'
  }
]

const App = () => {
  const styles = useStyles()

  return (
    <Grid container>
      <Grid container spacing={1} className={styles.topBg}>
        <Container maxWidth="lg">
          <Grid container className={styles.topContainer}>
            <Grid item xs={12} className="text-center" style={{ marginBottom: 24 }}>
              <img src={DellLogo} alt="Dell Pequenas Empresas" />
            </Grid>
            <Grid item xs={12} md={6} className="text-center">
              <Typography className="f-42 font--light line-height upcase">
                Cadastre-se agora e
              </Typography>
              <Typography className="f-42 font--bold line-height upcase">
                Seja o primeiro a saber
              </Typography>
              <img src={ImgDestaque} alt="Dell" className={styles.highLightImage} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Register />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid container>
        <Container maxWidth="lg">
          <Grid container justify="center">
            <Grid item xs={12} className={`${styles.topTitle} purple upcase`}>
              <Typography className="font--bold" align="center">
                Encontre a solução ideal para sua empresa
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {products.map((product, index) => (
                  <Grid item xs={6} md={3} key={index}>
                    <Product item={product} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} style={{ marginTop: 24, marginBottom: 24 }}>
              <RegisterButton />
            </Grid>
            <Grid item xs={12} className={`${styles.topTitle} upcase`} style={{ marginBottom: 32 }}>
              <Typography className="font--normal light--text" align="center">
                Com o programa de fidelidade 
                <span className="font--bold"> Dell advantage</span>, 
                você pode aproveitar os seus bônus durante a 
                <span className="font--bold"> Black Friday!</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {productDetails.map((details, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Details item={details} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} style={{ marginTop: 42, marginBottom: 42 }}>
              <RegisterButton />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid container className={`blue ${styles.footer}`}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img src={WhiteDellLogo} alt="Dell Pequenas Empresas" />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default App;
