import React from 'react';
import useForm from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import './FlipCard.css';
import axios from 'axios';

import { Card, CardContent, Grid, TextField, Button, Typography, CircularProgress } from '@material-ui/core';
import InputMask from 'react-input-mask';

// Styles
const useStyles = makeStyles(() => ({
    input: {
        '& input': {
            paddingTop: 12,
            paddingBottom: 12
        }
    },
    container: {
        height: '100%',
        alignItems: 'center',
        display: 'flex'
    },
    terms: {
        lineHeight: 0.5,
    }
}));

const Register = () => {
    const { register, handleSubmit, errors } = useForm();
    const [flip, setFlip] = React.useState(false);
    const [flipError, setFlipError] = React.useState(false);
    const [loader, setLoader] = React.useState(false)

    const styles = useStyles();
    const affiliateId = "3554";
    const secret = "ptudmuim";
    const offerId = "5226";
    const url = 'https://s5iff1huxa.execute-api.us-east-1.amazonaws.com/dev/leads/send';

    const handleSave = async (data) => {
        setLoader(true) // Start processing

        const urlParams = new URLSearchParams(window.location.search);
        var aff_id = urlParams.get('aff_id');
        var transaction_id = urlParams.get('transaction_id');
        var utm_source = urlParams.get('utm_source');
        var aff_sub = urlParams.get('aff_sub');

        var cellphone = data.phone.replace(/\D/g, "");
        if(aff_id === null)
        {
            aff_id = '1184';
        }

        const params = {
            affiliateId,
            secret,
            offerId,
            firstname: data.name,
            lastname: data.lastName,
            email: data.email,
            cellphone,
            aff_id,
            transaction_id,
            utm_source,
            product_choice: "immakers",
            product_choice2: "landingpage",
            product_choice3: `55${cellphone}`
        }
        
        try {
            await axios.post(url, params)
        } catch (error) {
            setFlipError(true)
        }
        
        try {
            await axios.get(`https://linkado.cc/p.ashx?o=496&e=221&f=pb&&r=${aff_sub}&t=${transaction_id}`);
        } catch (error) {
           
        }

        setLoader(false) // End processing
        setFlip(true)
    }

    const handleButtonClick = () => {
        window.open('https://www.dell.com/pt-br/work/shop/ofertas-black-friday/ab/ofertas-black-friday?mpt=888670362&mpcr=102579429&mpcrset=root&gclid=EAIaIQobChMIq-301-Se5QIViNRbCh0PVgacEAEYASAAEgKG2vD_BwE&dgc=ba&dgseg=bsd&cid=250273&lid=2056&acd=12255250273205636c102579429&VEN3=810705114549962826', '_blank')
    }

    const CardBack = () => (
        <CardContent className="flip-card-back">
            <Grid container className={styles.container}>
                <Grid item xs={12} className="purple--text text-center">
                    <Typography className="f-42 font--bold" align="center">
                        {flipError ? 'Ops! Tivemos algum problema.' : 'Cadastro feito com sucesso!'}
                    </Typography>
                    {flipError ?
                        (<Typography className="f-16 font-regular" align="center">
                            Por favor! Tente mais novamente mais tarde!
                        </Typography>) :
                        (<Button
                            className="green"
                            size="large"
                            style={{ marginTop: 16, paddingLeft: 32, paddingRight: 32 }}
                            onClick={handleButtonClick}>
                            Veja nossos produtos
                        </Button>)
                    }
                </Grid>
            </Grid>
        </CardContent>
    )

    return (
        <Card className={`flip-card ${flip ? 'flip' : ''}`}>
            {flip ? <CardBack /> : (
                <CardContent className="flip-card-inner">
                    <form className="flip-card-front" onSubmit={handleSubmit(handleSave)}>
                        <Grid container spacing={2} className="flip-card-front">
                            <Grid item xs={12} className="text-center">
                                <span className="font--regular f-16 upcase">
                                    Informe seus dados e seja o primeiro a saber das ofertas da
                                <span className="font--bold"> Black Friday </span>
                                    para sua empresa
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    className={styles.input}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Nome"
                                    inputRef={register({
                                        required: true
                                    })}
                                    inputProps={{
                                        name: 'name',
                                    }}
                                    helperText={errors.name && 'Por favor, preencha seu nome.'}
                                    disabled={loader}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    className={styles.input}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Sobrenome"
                                    inputRef={register({
                                        required: true
                                    })}
                                    inputProps={{
                                        name: 'lastName',
                                    }}
                                    helperText={errors.name && 'Por favor, preencha seu sobrenome.'}
                                    disabled={loader}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className={styles.input}
                                    fullWidth variant="outlined"
                                    placeholder="E-mail"
                                    inputRef={register({
                                        required: true,
                                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    })}
                                    inputProps={{ name: 'email' }}
                                    helperText={errors.email && 'E-mail inválido.'}
                                    disabled={loader}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputMask mask="(99)99999-9999" disabled={loader}>
                                    {(inputProps) => (
                                        <TextField
                                            className={styles.input}
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Telefone"
                                            inputRef={register({
                                                required: true,
                                                pattern: /\([1-9]{2}\)(?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}/
                                            })}
                                            inputProps={{
                                                name: 'phone',
                                            }}
                                            type="tel"
                                            helperText={errors.phone && 'Telefone inválido.'}
                                            disabled={loader}
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    size="large"
                                    className="green"
                                    type="submit"
                                    disabled={loader}>
                                    {loader ? 
                                        (<CircularProgress size={25} thickness={7} className="white--text" />) : 
                                        ('Cadastrar')
                                    }
                            </Button>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'justify' }}>
                                <Typography variant="caption" className={`font--regular light--text ${styles.terms}`}>
                                    Para se cadastrar, você nos informará o seu nome, endereço de e-mail e número de
                                    telefone e, ao clicar em “Cadastrar”, você declara que está ciente e aceita os
                                    termos e condições da <a href="https://www.dell.com/learn/br/pt/brcorp1/policies-privacy" target="_blank">
                                        Política de Privacidade da Dell
                                    </a> poderá receber e-mails,
                                    ligações ou mensagens com ofertas e anúncios das empresas Dell e parceiros
                                    autorizados. Você sempre poderá acessar a <a href="https://www.dell.com/learn/br/pt/brcorp1/policies-privacy" target="_blank">
                                        Política de Privacidade da Dell
                                    </a> para gerenciar as suas preferências de publicidade.
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            )}
        </Card>
    );
}

export default Register;