import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const Product = ({ item }) => {
    return ( 
        <Grid container className="light" style={{ padding: 16, height: 235 }}>
            <Grid item xs={12} style={{ marginBottom: 18 }}>
                <Typography className="f-20 font--regular" align="center">
                    { item.name }
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={item.image} alt={item.name} style={{ maxWidth: '100%' }} />
            </Grid>
        </Grid>
     );
}
 
export default Product;