import React from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Grid, Typography, Box } from '@material-ui/core';

// Styles
const useStyles = makeStyles(() => ({
    container: {
        padding: 16,
        paddingBottom: 0,
        '@media screen and (max-width: 960px)': {
            padding: 24
        }
    },
    title: {
        padding: 12,
        paddingLeft: 16,
        paddingRight: 16,
        lineHeight: 1,
    },
    image: {
        maxWidth: '90%',
        '@media screen and (max-width: 960px)': {
            maxWidth: '70%'
        }
    }
}))

const Details = ({ item }) => {
    const styles = useStyles()

    return (
        <Box className="light" style={{ height: '100%' }}>
            <Grid container justify="center" className={` ${styles.container}`}>
                <Grid item xs={12} md={6} style={{ marginBottom: 18 }}>
                    <Typography className={`${styles.title} purple f-15 font--bold upcase`} align="center">
                        {item.title}
                    </Typography>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={item.image} alt={item.name} height="inherit" className={styles.image} />
                </Grid>
                <Grid item xs={11} md={8}>
                    <Typography className="font--bold f-16" style={{ marginBottom: 4 }}>
                        {item.name}
                    </Typography>
                    <Typography className="font--regular f-14">
                        {item.description}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Details;